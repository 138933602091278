.top-padding {
    padding-top: 10px
}
.bottom-padding {
    padding-bottom: 10px
}

legend {
    border-color: #f6b600 !important;
    border-bottom: 2px solid;
    border-style: solid;
    width: 100%;
    border-left: 0;
    border-radius: 0;
    border-top: 0;
    border-right: 0;
    margin-bottom: 10px;
    margin-top: 20px;
    font-weight: bold;
}

.ui.labeled.icon.button, .ui.labeled.icon.buttons .button {
    position: relative;
    padding-left: 4.07142857em !important;
    padding-right: 1.5em !important;
    padding-top: 10px !important;
    margin-bottom: 10px;
}