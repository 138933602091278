.loader {
    position: absolute;
    z-index: 3;
    top: 25px;
    left: 30%;
}

.ui.segment:first-child {
    background-color: #ffffff !important;
}

.ui.card > .content > .header {
    color: #ffffff;
}

.authButtons .ui.button {
    background-color: #283659;
    color: #ffffff;
}

.ibakImage .ui.header > .image:not(.icon),
.ui.header > img {
    width: 10;
}
