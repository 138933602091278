.qrcode {
    opacity: 1;
}

.expirydate {
    opacity: 1;
}

.ui.disabled.input {
    opacity: 1;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
    opacity: 1;
}
