.ui.menu.fixed {
    background-color: #283659;
    padding-left: 50px;
    padding-right: 50px;
    height: 100px;
    border-bottom-color: #f6b600;
    border-bottom-width: 2px;
}

.navcontent {
    margin-top: 50px;
    display: flex;
}

@media only screen and (max-width: 767px) {
    .navcontent {
        margin-top: 280px;
    }
}

.ui.menu .item {
    color: #ffffff;
}

.ui.link.menu .item:active,
.ui.menu .link.item:active,
.ui.menu a.item:active {
    color: #ffffff;
}

.ui.menu .active.item {
    color: white;
}

.ui.menu a.item:hover {
    color: white;
}
.ui.menu a.item:hover:after {
    width: 80%;
    border-bottom: 2px solid white;
    bottom: 20px;
    content: "";
    left: 55%;
    position: absolute;
    transform: translateX(-50%);
}
button.ui.icon.button {
    min-width: 60px;
}

.ui.menu .active.item:hover {
    color: white;
}

.ui.menu a.item:active {
    color: white;
}
.ui.menu .active.item:after {
    width: 80%;
    border-bottom: 2px solid white;
    bottom: 20px;
    content: "";
    left: 55%;
    position: absolute;
    transform: translateX(-50%);
}

.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
    background: #dc3545;
}
.ui.disabled.toggle.checkbox .box:before, .ui.disabled.toggle.checkbox label:before {
    background: #e97b86;
}
.ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
    background-color: #218838!important;
}
.ui.disabled.toggle.checkbox input:checked~.box:before, .ui.disabled.toggle.checkbox input:checked~label:before {
    background: #74c686;
}