.panelContainer {
    flex-direction: column;
    margin-top: 25px;
}

.panelHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.panelHeaderText {
    align-self: center;
}

.panelHeaderRightContent {
    align-self: flex-end;
    margin-left: 20px;
}

.panelHeaderRightContent .ui.button {
    background-color: #283659;
    color: #ffffff;
}

.panelChildren {
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;
}

/* Info Button */
button.ui.massive.icon.button{
    padding: 6px;
    background-color: #f6b600;
    position: absolute;
    left: 15px;
}

button.ui.icon.button{
    background-color: #283659;
}
button.ui.icon.disabled.button{
    background-color: #93aac9;
}
h2.ui.header .ui.labeled.icon.button{
    position: absolute;
    right: 0px;
    top: 10px;
}

div.panelContainer{
    min-width: 700px;
}

.ui.button {
    color: white;
}

.ui.button:hover {
    color: white;
}
