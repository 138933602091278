/* .ui.card > :first-child {
	background-color: #283659;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 180px;
	padding-right: 180px;
} */

.authcardButtons .ui.button {
	background-color: #283659;
	color: #ffffff;
}

/* .ui.card > .content > .header {
	color: #000000;
	margin-top: 10px;
	margin-bottom: 20px;
} */

.ui.header > .image:not(.icon),
.ui.header > img {
	width: 13em;
}

.ui.horizontal.divider:after,
.ui.horizontal.divider:before {
	width: 17%;
}

.children {
	margin-top: 50px;
}

.buttons {
	margin-top: 30px;
}
